import React from 'react';
import { Link } from 'react-router-dom';
import WhyChooseImg1 from '../../assets/images/backgrounds/Banner21-2.gif';

const WhyChoose = () => {
    return (
        <>
            {/* Why Choose One Start */}
            <section className="why-choose-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="why-choose-one__left">
                                <div className="section-title text-left">
                                    <div className="section-title__tagline-box">
                                        <span className="section-title__tagline">Why Choose Us</span>
                                    </div>
                                    <h2 className="section-title__title">
                                        Trusted Expertise in Customs & Logistics Services
                                    </h2>
                                </div>
                                <p className="why-choose-one__text">
                                    Recognized for our efficient customs clearance processes, ensuring timely and compliant movement of goods.<br />
                                    Extensive experience in managing international logistics with seamless import and export services.<br />
                                    Partnerships with global carriers to ensure on-time delivery and cost-effective solutions.<br />
                                    Expertise in navigating complex regulatory environments, offering full compliance with Ethiopian customs and industry standards.<br />
                                    Comprehensive logistics services from customs clearance to last-mile delivery, tailored to meet your needs.
                                </p>
                                <div className="why-choose-one__btn-box">
                                    <Link to="/about" className="thm-btn why-choose-one__btn">
                                        Read More
                                        <span />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="why-choose-one__right">
                                <div className="why-choose-one__img wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                                    <img src={WhyChooseImg1} alt="Why Choose Us" />
                                    <div className="why-choose-one__delivery-box">
                                        <div className="icon">
                                            <span className="icon-airplane" />
                                        </div>
                                        <p>
                                            2 DAYS
                                            <br /> DELIVERY
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Why Choose One End */}
        </>
    );
}

export default WhyChoose;
