import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Alert, Pagination, Form } from "react-bootstrap";
import { format, parseISO } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import documentService from "../../services/document.services"; // Adjust the path as needed
import { MdDelete } from "react-icons/md";
import { FaDownload, FaFilePdf } from "react-icons/fa"; // Import PDF icon
import './DocumentsList.css'; // Import your CSS

const DocumentListById = () => {  // Fix the typo in component name
  const [customDeclarations, setCustomDeclarations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;
  const { user } = useAuth();
  const token = ""; // Add actual token handling here

  useEffect(() => {
    const fetchCustomDeclarations = async () => {
      if (!user.user_id) {
        setApiError(true);
        setApiErrorMessage("Customer ID is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await documentService.getDocumentById(user.user_id, token);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error: ${response.status} ${response.statusText} - ${errorText}`);
        }
        const data = await response.json();
        setCustomDeclarations(data);
        console.log(data);
      } catch (error) {
        setApiError(true);
        setApiErrorMessage(error.message || "An error occurred while fetching data.");
        console.error("Error fetching custom declarations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomDeclarations();
  }, [token, user.user_id]);

  const handleDelete = async (id) => {
    try {
      const response = await documentService.deleteDocument(id, token);
      if (response.ok) {
        setCustomDeclarations((prevDeclarations) => prevDeclarations.filter(declaration => declaration.document_id !== id));
        setSuccessMessage("Custom Declaration deleted successfully!");
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to delete custom declaration: ${errorText}`);
      }
    } catch (error) {
      setApiError(true);
      setApiErrorMessage(error.message || "An error occurred while deleting the custom declaration.");
      console.error("Error deleting custom declaration:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = parseISO(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : format(date, "MMMM dd, yyyy");
  };

  const handleDownload = (file_path) => {
    if (!file_path) {
      alert("File path is not provided!");
      return;
    }
    window.open(`http://localhost:3003/${file_path}`, "_blank");
  };

  const filteredDeclarations = customDeclarations.filter(declaration =>
    declaration.project_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastDeclaration = currentPage * itemsPerPage;
  const indexOfFirstDeclaration = indexOfLastDeclaration - itemsPerPage;
  const currentDeclarations = filteredDeclarations.slice(indexOfFirstDeclaration, indexOfLastDeclaration);
  const totalPages = Math.ceil(filteredDeclarations.length / itemsPerPage);

  return (
    <section className="custom-declaration-section pb-64">
      {loading ? (
        <div className="auto-container text-center">
          <Spinner animation="border" />
          <p>Loading custom declarations...</p>
        </div>
      ) : apiError ? (
        <div className="auto-container">
          <div className="declaration-title">
            <h2>{apiErrorMessage}</h2>
          </div>
        </div>
      ) : (
        <div className="auto-container h-screen">
          <div className="declaration-title">
            <h2>Document Declarations</h2>
          </div>

          <Form className="mb-3">
            <Form.Group controlId="search">
              <Form.Label>Search by Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter project name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
          </Form>

          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <div className="table-scroll-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Company Name</th>
                  <th>Project Name</th>
                  <th>File</th>
                  <th>Project Type</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Delete</th>
                  <th>View and Download</th>
                </tr>
              </thead>
              <tbody>
                {currentDeclarations.length > 0 ? (
                  currentDeclarations.map((declaration, index) => {
                    const isFirstOccurrence = index === 0 || 
                      (currentDeclarations[index - 1].customer_id !== declaration.customer_id) ||
                      (currentDeclarations[index - 1].customer_firstName !== declaration.customer_firstName) ||
                      (currentDeclarations[index - 1].customer_lastName !== declaration.customer_lastName) ||
                      (currentDeclarations[index - 1].company_name !== declaration.company_name) ||
                      (currentDeclarations[index - 1].project_name !== declaration.project_name);

                    return (
                      <tr key={declaration.document_id}>
                        <td className="text-center">
                          {isFirstOccurrence ? declaration.customer_id : ""}
                        </td>
                        <td className="text-center">
                          {isFirstOccurrence ? `${declaration.customer_firstName} ${declaration.customer_lastName}` : ""}
                        </td>
                        <td className="text-center">
                          {isFirstOccurrence ? declaration.company_name : ""}
                        </td>
                        <td className="text-center">
                          {isFirstOccurrence ? declaration.project_name : ""}
                        </td>
                        <td className="text-center">
                          <Button variant="link" onClick={() => handleDownload(declaration.file_path)}>
                            <FaFilePdf size={24} />
                          </Button>
                        </td>
                        <td className="text-center">{declaration.document_type}</td>
                        <td className="text-center">{formatDate(declaration.upload_date)}</td>
                        <td className="text-center">{declaration.status}</td>
                        <td className="text-center">
                          <Button variant="danger" onClick={() => handleDelete(declaration.document_id)}>
                            <MdDelete />
                          </Button>
                        </td>
                        <td className="text-center">
                          <Button className="text-btn-center" variant="primary" onClick={() => handleDownload(declaration.file_path)}>
                            <FaDownload />
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">No custom declarations found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="pagination-container">
            <Pagination>
              <Pagination.Prev 
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                disabled={currentPage === 1} 
              />
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next 
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                disabled={currentPage === totalPages} 
              />
            </Pagination>
          </div>
        </div>
      )}
    </section>
  );
};

export default DocumentListById;
