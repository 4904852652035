import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddCustomForm from "../../components/Custom/AddCustom";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import customerService from "../../services/customer.services";
import { useAuth } from "../../context/AuthContext";

function AddCustomer() {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const loggedInUserToken = user?.token || "";

  useEffect(() => {
    if (!customerId) {
      setError("Customer ID is missing");
      setLoading(false);
      return;
    }

    const fetchCustomerData = async () => {
      try {
        const customerResponse = await customerService.getCustomerById(
          customerId,
          loggedInUserToken
        );
        if (!customerResponse.ok) {
          throw new Error("Failed to fetch customer data");
        }
        const customerData = await customerResponse.json();
        console.log("Retrieved data:", customerData);
        setCustomer(customerData); // Ensure this is the correct path
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId, loggedInUserToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          {customer ? (
            <div>
              <h3>Customer Details</h3>
              <p>Email: {customer.customer_email}</p>
              <p>Phone: {customer.customer_phone}</p>
              <p>First Name: {customer.customer_firstName}</p>
              <p>Last Name: {customer.customer_lastName}</p>
              <p>Company: {customer.company_name}</p>
              <p>Address: {customer.address}</p>
              <button>Edit Customer Info</button>
            </div>
          ) : (
            <div>No customer details found.</div>
          )}

          <h3>Add Custom Declaration</h3>
          <AddCustomForm customerId={customerId} />
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
