import { useState } from "react";
import { motion } from "framer-motion";
import documentService from "../../services/document.services";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import './AddDocumentsForm.css'; // Importing the CSS file
import { MdDelete } from "react-icons/md";

function AddDocumentsForm({ customerId }) {
  const [documents, setDocuments] = useState([createEmptyDocument()]);
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  

  const { user } = useAuth();
  const loggedInUserToken = user?.token || "";
  const navigate = useNavigate();

  function createEmptyDocument() {
    return {
      projectName: "",
      documentType: "",
      customDocumentType: "",
      file: null,
      status: "",
      isMandatory: false,
      errors: {}
    };
  }

  // Define the document change handler first
  const handleDocumentChange = (index, e) => {
    const { name, value, type, checked, files } = e.target;
    setDocuments(documents.map((doc, i) => {
      if (i !== index) return doc;
      if (type === "checkbox") {
        return { ...doc, [name]: checked };
      } else if (type === "file") {
        return { ...doc, [name]: files[0] };
      } else {
        return { ...doc, [name]: value };
      }
    }));
  };

  // Define addDocumentForm next
  const addDocumentForm = () => {
    if (documents.length < 20) {
      setDocuments([...documents, createEmptyDocument()]);
    }
  };

  const removeDocumentForm = (index) => {
    if (documents.length > 1) {
      setDocuments(documents.filter((_, i) => i !== index));
    }
  };

  const validateDocuments = () => {
    const newDocuments = documents.map(doc => {
      const newErrors = {};
      if (!doc.projectName) newErrors.projectName = "Project name is required";
      if (!doc.documentType && !doc.customDocumentType) {
        newErrors.documentType = "Document type is required";
      }
      if (!doc.file) newErrors.file = "File is required";
      else if (
        doc.file.size > 5 * 1024 * 1024 || // 5MB limit
        !["application/pdf", "application/msword"].includes(doc.file.type)
      ) {
        newErrors.file = "File must be a PDF or Word document and less than 5MB";
      }
      if (!doc.status) newErrors.status = "Status is required";
      return { ...doc, errors: newErrors };
    });
    setDocuments(newDocuments);
    return newDocuments.every(doc => Object.keys(doc.errors).length === 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateDocuments()) return;

    const formDataArray = documents.map(doc => {
      const formData = new FormData();
      formData.append("project_name", doc.projectName);
      formData.append("document_type", doc.documentType || doc.customDocumentType);
      formData.append("file", doc.file);
      formData.append("status", doc.status);
      formData.append("is_mandatory", doc.isMandatory);
      formData.append("customer_id", customerId);
      return formData;
    });

    setIsLoading(true);
    setServerError(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success messages

    try {
      const responses = await Promise.all(formDataArray.map(formData =>
        documentService.createDocument(formData, loggedInUserToken)
      ));

      const allOk = responses.every(response => response.ok);

      if (allOk) {
        setIsSubmitted(true);
        setSuccessMessage("Documents added successfully!");
        setDocuments([createEmptyDocument()]); // Reset the form
        setTimeout(() => {
          navigate(`/admin/viewdocumentbyid/{id}`);
        }, 2000);
      } else {
        const errorText = await Promise.all(responses.map(r => r.text()));
        setServerError(`HTTP error! ${errorText.join(", ")}`);
      }
    } catch (error) {
      setServerError(`An error occurred while adding documents: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact-section h-[100%] mb-64">
      <div className="auto-container">
        <div className="contact-title">
          <h2>{isSubmitted ? "Documents Added" : "Add Documents"}</h2>
        </div>
        <hr className="divider" />
        <div className="row clearfix">
          <div className="form-column col-lg-12">
            <div className="inner-column">
              <div className="contact-form">
                {!isSubmitted ? (
                  <motion.form
                    onSubmit={handleSubmit}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {serverError && <div className="error-message">{serverError}</div>}
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    
                    <div className="form-group col-md-12 text-right">
                      <button
                        type="button"
                        className="theme-btn btn-style-one"
                        onClick={addDocumentForm}
                        disabled={isLoading || documents.length >= 20}
                      >
                        +
                      </button>
                    </div>

                    <div className="table-responsive">
                      <table className="document-table table">
                        <thead>
                          <tr>
                            <th>Project Name</th>
                            <th>Document Type</th>
                            <th>Custom Document Type</th>
                            <th>File</th>
                            <th>Status</th>
                            <th>Mandatory</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((doc, index) => (
                            <motion.tr
                              key={index}
                              initial={{ scale: 0.95 }}
                              animate={{ scale: 1 }}
                              exit={{ scale: 0.95 }}
                              transition={{ duration: 0.2 }}
                            >
                              <td>
                                <input
                                  type="text"
                                  name="projectName"
                                  value={doc.projectName}
                                  onChange={(e) => handleDocumentChange(index, e)}
                                  placeholder="Project name"
                                  disabled={isLoading}
                                />
                                {doc.errors.projectName && (
                                  <div className="validation-error" role="alert">
                                    {doc.errors.projectName}
                                  </div>
                                )}
                              </td>
                              <td>
                                <select
                                  name="documentType"
                                  value={doc.documentType}
                                  onChange={(e) => handleDocumentChange(index, e)}
                                  disabled={isLoading}
                                >
                                  <option value="">Select Document Type</option>
                                  <option value="Commercial Invoice">Commercial Invoice</option>
                                  <option value="Packing List">Packing List</option>
                                  <option value="License">License</option>
                                  <option value="Certificate of Origin">Certificate of Origin</option>
                                  <option value="Approve EPA">Approve EPA</option>
                                  <option value="Approved INSA">Approved INSA</option>
                                  <option value="Approve ECA">Approve ECA</option>
                                  <option value="AWB/Bill of Lading">AWB/Bill of Lading</option>
                                  <option value="Others">Others</option>
                                </select>
                                {doc.errors.documentType && (
                                  <div className="validation-error" role="alert">
                                    {doc.errors.documentType}
                                  </div>
                                )}
                              </td>
                              <td>
                                {doc.documentType === "Others" && (
                                  <input
                                    type="text"
                                    name="customDocumentType"
                                    value={doc.customDocumentType}
                                    onChange={(e) => handleDocumentChange(index, e)}
                                    placeholder="Specify Document Type"
                                    disabled={isLoading}
                                  />
                                )}
                              </td>
                              <td>
                                <input
                                  type="file"
                                  name="file"
                                  onChange={(e) => handleDocumentChange(index, e)}
                                  disabled={isLoading}
                                />
                                {doc.errors.file && (
                                  <div className="validation-error" role="alert">
                                    {doc.errors.file}
                                  </div>
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="status"
                                  value={doc.status}
                                  onChange={(e) => handleDocumentChange(index, e)}
                                  placeholder="Status"
                                  disabled={isLoading}
                                />
                                {doc.errors.status && (
                                  <div className="validation-error" role="alert">
                                    {doc.errors.status}
                                  </div>
                                )}
                              </td>
                              <td>
                                <label>
                                  <input
                                    type="checkbox"
                                    name="isMandatory"
                                    checked={doc.isMandatory}
                                    onChange={(e) => handleDocumentChange(index, e)}
                                    disabled={isLoading}
                                  />
                                </label>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  onClick={() => removeDocumentForm(index)}
                                  disabled={isLoading}
                                >
                                  <MdDelete className="fs-6"/>
                                </button>
                              </td>
                            </motion.tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="form-group col-md-12">
                      <button
                        type="submit"
                        className="theme-btn btn-style-one"
                        disabled={isLoading}
                      >
                        {isLoading ? "Uploading..." : "Submit"}
                      </button>
                    </div>
                  </motion.form>
                ) : (
                  <div className="form-group col-md-12">
                    <button
                      type="button"
                      className="theme-btn btn-style-one"
                      onClick={() => {
                        setDocuments([createEmptyDocument()]);
                        setIsSubmitted(false);
                      }}
                    >
                      Add More Documents
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddDocumentsForm;
