// import Swiper core and required modules
import { Link } from 'react-router-dom';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import bgImg1 from "../../assets/images/backgrounds/banner-images.jpg";
import bgImg2 from "../../assets/images/backgrounds/banner21-1.gif";
import bgImg3 from "../../assets/images/backgrounds/banner21-4.gif";
import BannerTitleIcon from "../../assets/images/icon/main-slider-sub-title-icon.png"

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
        delay: 7000,
        disableOnInteraction: false,
    },
    loop: true,
    navigation: {
        nextEl: '.h1n',
        prevEl: '.h1p',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
};

function Banner() {
    return (
        <>
        {/* banner-one */}
        <section className="main-slider">
            <Swiper {...swiperOptions} className="swiper-container thm-swiper__slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div
                  className="main-slider__bg"
                  style={{ backgroundImage: `url(${bgImg1})` }}
                />
                <div className="main-slider__shape-1" />
                <div className="main-slider__shape-2" />
                <div className="main-slider__shape-3" />
                <div className="main-slider__shape-4" />
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="main-slider__content">
                        <div className="main-slider__sub-title-box">
                          <div className="main-slider__sub-title-icon">
                            <img
                              src={BannerTitleIcon}
                              alt=""
                            />
                          </div>
                          <p className="main-slider__sub-title">Best custom Clearance services</p>
                        </div>
                        <h2 className="main-slider__title">
                          Reliable <span>Responsive</span> <br /> custom Clearance services
                        </h2>
                        <p className="main-slider__text">
                        Logistics customer service focuses on providing efficient, timely communication and
                        <br/> problem resolution related to shipping, tracking, inventory, 
                        <br/>and delivery, ensuring customer satisfaction and smooth supply chain operations.
                        </p>
                        <div className="main-slider__btn-and-call-box">
                          <div className="main-slider__btn-box">
                            <Link to="/about" className="thm-btn main-slider__btn">
                              Read more
                              <span />
                            </Link>
                          </div>
                          <div className="main-slider__call">
                            <div className="main-slider__call-icon">
                              <span className="icon-phone" />
                            </div>
                            <div className="main-slider__call-number">
                              <p>Need help?</p>
                              <h5>
                                <Link to="tel:0919393210">+251911094804</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <div
                  className="main-slider__bg"
                  style={{ backgroundImage: `url(${bgImg2})` }}
                />
                <div className="main-slider__shape-1" />
                <div className="main-slider__shape-2" />
                <div className="main-slider__shape-3" />
                <div className="main-slider__shape-4" />
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="main-slider__content">
                        <div className="main-slider__sub-title-box">
                          <div className="main-slider__sub-title-icon">
                            <img
                              src={BannerTitleIcon}
                              alt=""
                            />
                          </div>
                          <p className="main-slider__sub-title">Best custom Clearance services</p>
                        </div>
                        <h2 className="main-slider__title">
                          Reliable <span>Responsive</span> <br /> custom Clearance services
                        </h2>
                        <p className="main-slider__text">
                        Logistics customer service focuses on providing efficient, timely communication and
                        <br/> problem resolution related to shipping, tracking, inventory, 
                        <br/>and delivery, ensuring customer satisfaction and smooth supply chain operations.
                        </p>
                        <div className="main-slider__btn-and-call-box">
                          <div className="main-slider__btn-box">
                            <Link to="/about" className="thm-btn main-slider__btn">
                              Read more
                              <span />
                            </Link>
                          </div>
                          <div className="main-slider__call">
                            <div className="main-slider__call-icon">
                              <span className="icon-phone" />
                            </div>
                            <div className="main-slider__call-number">
                              <p>Need help?</p>
                              <h5>
                              <Link to="tel:0919393210">+251911094804</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide className="swiper-slide">
                <div
                  className="main-slider__bg"
                  style={{ backgroundImage: `url(${bgImg3})` }}
                />
                <div className="main-slider__shape-1" />
                <div className="main-slider__shape-2" />
                <div className="main-slider__shape-3" />
                <div className="main-slider__shape-4" />
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="main-slider__content">
                        <div className="main-slider__sub-title-box">
                          <div className="main-slider__sub-title-icon">
                            <img
                              src={BannerTitleIcon}
                              alt=""
                            />
                          </div>
                          <p className="main-slider__sub-title">Best custom Clearance services</p>
                        </div>
                        <h2 className="main-slider__title">
                          Reliable <span>Responsive</span> <br /> custom Clearance services
                        </h2>
                        <p className="main-slider__text">
                        Logistics customer service focuses on providing efficient, timely communication and
                        <br/> problem resolution related to shipping, tracking, inventory, 
                        <br/>and delivery, ensuring customer satisfaction and smooth supply chain operations.
                        </p>
                        <div className="main-slider__btn-and-call-box">
                          <div className="main-slider__btn-box">
                            <Link to="/about" className="thm-btn main-slider__btn">
                              Read more
                              <span />
                            </Link>
                          </div>
                          <div className="main-slider__call">
                            <div className="main-slider__call-icon">
                              <span className="icon-phone" />
                            </div>
                            <div className="main-slider__call-number">
                              <p>Need help?</p>
                              <h5>
                              <Link to="tel:0919393210">+251911094804</Link>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-pagination" id="main-slider-pagination" />

        </section>
        {/* banner-one */}
      </>
    );
}

export default Banner;
