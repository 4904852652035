import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Alert, Pagination, Form } from "react-bootstrap";
import { format } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import customDeclarationService from "../../services/customDeclaration.services";
import { MdDelete } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

const AdminCustomList = () => {
  const [customDeclarations, setCustomDeclarations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newDeclaration, setNewDeclaration] = useState({ file_name: '', file_path: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDeclarations, setFilteredDeclarations] = useState([]);
  const itemsPerPage = 10;
  const { user } = useAuth();
  
  const token = ""; // Replace with actual token handling

  useEffect(() => {
    const fetchCustomDeclarations = async () => {
      if (!user.user_id) {
        setApiError(true);
        setApiErrorMessage("Customer ID is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await customDeclarationService.getAllCustomDeclarations(token);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setCustomDeclarations(data);
        setFilteredDeclarations(data); // Initialize filtered declarations
      } catch (error) {
        setApiError(true);
        setApiErrorMessage(error.message || "An error occurred while fetching data.");
        console.error("Error fetching custom declarations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomDeclarations();
  }, [token, user.user_id]);

  const handleAddCustomDeclaration = async () => {
    setIsSubmitting(true);
    try {
      const response = await customDeclarationService.createCustomDeclaration(newDeclaration, token);
      if (!response.ok) {
        throw new Error(`Failed to add custom declaration: ${await response.text()}`);
      }
      const result = await response.json();
      setCustomDeclarations((prev) => [...prev, result]);
      setFilteredDeclarations((prev) => [...prev, result]); // Update filtered as well
      setSuccessMessage("Custom Declaration added successfully!");
      setNewDeclaration({ file_name: '', file_path: '' });
    } catch (error) {
      setApiError(true);
      setApiErrorMessage(error.message || "An error occurred while adding the custom declaration.");
      console.error("Error adding custom declaration:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await customDeclarationService.deleteCustomDeclaration(id, token);
      if (response.ok) {
        setCustomDeclarations((prevDeclarations) => prevDeclarations.filter(declaration => declaration.declaration_id !== id));
        setFilteredDeclarations((prevDeclarations) => prevDeclarations.filter(declaration => declaration.declaration_id !== id)); // Update filtered
        setSuccessMessage("Custom Declaration deleted successfully!");
      } else {
        throw new Error(`Failed to delete custom declaration: ${await response.text()}`);
      }
    } catch (error) {
      setApiError(true);
      setApiErrorMessage(error.message || "An error occurred while deleting the custom declaration.");
      console.error("Error deleting custom declaration:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : format(date, "MM-dd-yyyy | HH:mm");
  };

  const handleDownload = (file_path) => {
    if (!file_path) {
      alert("File path is not provided!");
      return;
    }
    window.open(`http://localhost:3003/${file_path}`, "_blank");
  };

  const handleSearch = () => {
    const results = customDeclarations.filter(declaration =>
      declaration.project_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDeclarations(results);
    setCurrentPage(1); // Reset to first page when searching
  };

  const indexOfLastDeclaration = currentPage * itemsPerPage;
  const indexOfFirstDeclaration = indexOfLastDeclaration - itemsPerPage;
  const currentDeclarations = filteredDeclarations.slice(indexOfFirstDeclaration, indexOfLastDeclaration);
  const totalPages = Math.ceil(filteredDeclarations.length / itemsPerPage);

  return (
    <section className="custom-declaration-section">
      {loading ? (
        <div className="auto-container text-center">
          <Spinner animation="border" />
          <p>Loading custom declarations...</p>
        </div>
      ) : apiError ? (
        <div className="auto-container">
          <h2>{apiErrorMessage}</h2>
        </div>
      ) : (
        <div className="auto-container h-screen">
          <h2>Custom Declarations</h2>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          <div className="mb-3 d-flex">
            <Form.Control
              type="text"
              placeholder="Search by Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="me-2"
            />
            <Button onClick={handleSearch}>Search</Button>
          </div>

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>Company Name</th>
                <th>File Name</th>
                <th>Received Date</th>
                <th>Delete</th>
                <th>View Document</th>
              </tr>
            </thead>
            <tbody>
              {currentDeclarations.length > 0 ? (
                currentDeclarations.map((declaration) => (
                  <tr key={declaration.declaration_id}>
                    <td className="text-center">{declaration.customer_firstName} {declaration.customer_lastName}</td>
                    <td className="text-center">{declaration.company_name}</td>
                    <td className="text-center">{declaration.file_name}</td>
                    <td className="text-center">{formatDate(declaration.upload_date)}</td>
                    <td className="text-center">
                      <Button variant="danger" onClick={() => handleDelete(declaration.declaration_id)}><MdDelete/></Button>
                    </td>
                    <td className="text-center">
                      <Button variant="primary" onClick={() => handleDownload(declaration.file_path)}><FaDownload/></Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">No custom declarations found.</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
    </section>
  );
};

export default AdminCustomList;
