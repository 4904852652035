import React from "react";
import { Link } from "react-router-dom";

function AdminMenu({ user_id }) {
  if (!user_id) {
    console.error("AdminMenu received an undefined user_id.");
  }
  return (
    <div className="h-screen">
      <div className="admin-menu ">
        <h2>Admin Menu</h2>
      </div>
      <div className="list-group">
        <h1 className="list-group-item fs-5">
          Dashboard
        </h1>
        <Link to="/admin/user" className="list-group-item">
          User Manage
        </Link>
        
        <Link to="/admin/view-document" className="list-group-item">
          View Customer Document
        </Link>
        <Link
          to="/admin/Admincustomlist"
          className="list-group-item"
        >
          View Custom Document
        </Link>
      </div>
    </div>
  );
}

export default AdminMenu;
