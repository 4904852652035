import React from "react";
import AddEmployeeForm from "../../components/User/RegisterUserForm";

const Register = () => {
  return (
    <div>
      <AddEmployeeForm />
    </div>
  );
};

export default Register;
