import React from 'react';
import CounterOne from '../../components/About/CounterOne';
import videoImg from '../../assets/images/backgrounds/truck-8656658_1280 (1).jpg';

const counters = [
  { iconClass: 'icon-schedule', end: 100, suffix: '+', text: 'Successful Customs Clearances' },
  { iconClass: 'icon-schedule', end: 2, suffix: 'k', plus: true, text: 'Experienced Team Members' },
  { iconClass: 'icon-schedule', end: 3, suffix: 'k', plus: true, text: 'Happy Clients Served' }
];

const Features = () => {
  return (
    <>
      {/* Message One Start */}
      <CounterOne
        imageSrc={videoImg}
        videoId="jNQXAC9IVRw" // YouTube video ID
        videoCaption="Watch our video"
        tagline="Our Features"
        title="Streamlining Your Logistics & Customs Clearance Needs"
        description="Our customs clearance services ensure your goods move efficiently through borders, overcoming regulatory hurdles and ensuring timely delivery to your destination."
        counters={counters}
      />
      {/* Message One End */}
    </>
  );
}

export default Features;
