import React from 'react';
// Import the ServiceCard component
import ServiceItem from '../../components/Service/ServiceItem';

const ServicesOne = () => {
    const services = [
        { 
            iconClass: 'icon-postbox', 
            title: 'Fast and Reliable Customs Clearance', 
            link: '/express-freight-solutions', 
            description: 'Efficient customs clearance services for both import and export shipments. Timely processing with minimal delays and full compliance with regulations.'
        },
        { 
            iconClass: 'icon-customer-service', 
            title: 'Sea Freight Customs Clearance', 
            link: '/quick-move-logistics', 
            description: 'Seamless customs clearance for sea freight shipments. We handle all documentation and ensure smooth import/export processes at competitive rates.'
        },
        { 
            iconClass: 'icon-postbox', 
            title: 'Air Freight Customs Clearance', 
            link: '/speedy-dispatch', 
            description: 'Specialized customs clearance for time-sensitive air freight. Fast processing and efficient handling of all necessary paperwork for international air cargo.'
        },
        { 
            iconClass: 'icon-container', 
            title: 'Comprehensive Customs Clearance', 
            link: '/speedy-dispatch', 
            description: 'Expert management of all customs procedures for import and export goods. We ensure compliance with local and international regulations to avoid delays.'
        },
        { 
            iconClass: 'icon-container', 
            title: 'Post-Clearance Logistics', 
            link: '/speedy-dispatch', 
            description: 'Complete post-clearance services including last-mile delivery, warehousing, and inventory management. We ensure smooth delivery of your goods after customs clearance.'
        },
    ];
    
    return (
        <>
            {/*Services One Start*/}
            <section className="services-one">
                <div className="container">
                    <div className="section-title text-center">
                        <div className="section-title__tagline-box">
                            <span className="section-title__tagline">LATEST SERVICE</span>
                        </div>
                        <h2 className="section-title__title">
                            Your Trusted Partner for Customs Clearance Services
                        </h2>
                    </div>
                    <div className="row">
                        {services.map((service, index) => (
                            <div key={index} className="col-xl-4 col-lg-4">
                                <ServiceItem
                                    iconClass={service.iconClass}
                                    title={service.title}
                                    link={service.link}
                                    description={service.description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/*Services One End*/}
        </>
    );
}

export default ServicesOne;
