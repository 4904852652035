import React from 'react';
import Banner from "./Banner"
import ServicesOne from "./ServicesOne"
import WhyChoose from "./WhyChoose"
import Project from "./Project"
import About from './AboutSection';
import Features from './Features';
import Team from './Team';
import WhyareweSec from './WhyAreWeSec';
import Brand from './Brand';

import TestimonialsSection from './TestimonialsSection';




const HomeMain = () => {
	return (
		<React.Fragment>
			<Banner />
			<ServicesOne />
			<WhyChoose />
			<Project />
			<Brand />
			<Features />
			<Team/>
			<WhyareweSec />
			
			<TestimonialsSection />
			
		</React.Fragment>
	);
}

export default HomeMain;