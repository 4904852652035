// Import the auth hook
import { useAuth } from "../../context/AuthContext";
// Import the login form component
import LoginForm from "../../components/Login/LoginForm";
// Import the admin menu component
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import EditStatus from "../../components/EditUser/EditStatus";
// Import the DocumentsList component



function AdminStatus() {
  // Destructure the auth hook
  const { isLogged, isAdmin } = useAuth();

  if (isLogged) {
    if (isAdmin) {
      return (
        <div className="container-fluid admin-pages">
          <div className="row">
            <div className="col-md-3 admin-left-side">
              <AdminMenu />
            </div>
            <div className="col-md-9 admin-right-side">
              <EditStatus />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1>You are not authorized to access this page</h1>
        </div>
      );
    }
  } else {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }
}

export default AdminStatus;
