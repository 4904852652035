import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Alert, Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import documentService from "../../services/document.services";
import './DocumentsList.css';
import { MdDelete, MdEdit } from "react-icons/md";
import { FaDownload, FaFile } from "react-icons/fa";
import { IoMdEye } from "react-icons/io";

const DocumentsList = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useAuth();
  const token = user?.user_token || "";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await documentService.getAllDocuments(token);
        const data = await response.json();

        if (response.ok && data.success) {
          setDocuments(data.data || []);
        } else {
          setApiError(true);
          setApiErrorMessage(data.message || "An error occurred while fetching data");
        }
      } catch (error) {
        setApiError(true);
        setApiErrorMessage("An error occurred while fetching data");
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [token]);

  const handleDelete = async (id) => {
    try {
      const response = await documentService.deleteDocument(id, token);
      if (response.ok) {
        setDocuments((prevDocuments) => prevDocuments.filter(doc => doc.document_id !== id));
        setSuccessMessage("Document deleted successfully!");
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to delete document: ${errorText}`);
      }
    } catch (error) {
      setApiError(true);
      setApiErrorMessage(error.message || "An error occurred while deleting the document.");
      console.error("Error deleting document:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/document/edit/${id}`);
  };

  const handlestatus = (id) => {
    navigate(`/admin/status/${id}`);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    const options = { month: 'long', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(',', '');
  };

  const handleDownload = (file_path) => {
    const downloadUrl = `http://localhost:3003/${file_path}`;
    window.open(downloadUrl, "_blank");
  };

  const filteredDocuments = documents.filter(doc =>
    doc.project_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalDocuments = filteredDocuments.length;
  const totalPages = Math.ceil(totalDocuments / itemsPerPage);
  const indexOfLastDocument = currentPage * itemsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - itemsPerPage;
  const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);

  return (
    <div className="pb-48">
      {loading ? (
        <LoadingState />
      ) : apiError ? (
        <ErrorState message={apiErrorMessage} />
      ) : (
        <DocumentTable 
          documents={currentDocuments} 
          onEdit={handleEdit} 
          onDelete={handleDelete} 
          onDownload={handleDownload}
          handlestatus={handlestatus} // Pass the handlestatus function
          formatDate={formatDate} 
          successMessage={successMessage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
      )}
    </div>
  );
};

// Loading State Component
const LoadingState = () => (
  <section className="document-section text-center h-screen">
    <Spinner animation="border" />
    <p>Loading documents...</p>
  </section>
);

// Error State Component
const ErrorState = ({ message }) => (
  <section className="document-section text-center">
    <h2>{message}</h2>
  </section>
);

// Document Table Component
const DocumentTable = ({ documents, onEdit, onDelete, onDownload, handlestatus, formatDate, successMessage, totalPages, setCurrentPage, searchTerm, setSearchTerm }) => (
  <section className="document-section">
    <Container>
      <Row>
        <Col xs={12}>
          <h2 className="text-center">Documents</h2>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <Form inline className="mb-3">
            <Form.Control 
              type="text" 
              placeholder="Search by Project Name" 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)} 
              className="mr-sm-2" 
            />
          </Form>
          <div className="table-responsive">
            <Table striped bordered hover responsive size="sm" className="compact-table">
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Company Name</th>
                  <th>Project Name</th>
                  <th>Document Type</th>
                  <th>Uploaded Date</th>
                  <th>Status</th>
                  <th>Is Mandatory</th>
                  <th>Edit/Delete</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => (
                  <DocumentRow 
                    key={document.document_id} 
                    document={document} 
                    onEdit={onEdit} 
                    onDelete={onDelete} 
                    onDownload={onDownload} 
                    formatDate={formatDate} 
                    handlestatus={handlestatus} // Pass handlestatus to DocumentRow
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-between">
            <Pagination totalPages={totalPages} setCurrentPage={setCurrentPage} />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

// Pagination Component
const Pagination = ({ totalPages, setCurrentPage }) => {
  const pageNumbers = [...Array(totalPages).keys()].map(i => i + 1);

  return (
    <div className="pagination">
      {pageNumbers.map(number => (
        <Button key={number} onClick={() => setCurrentPage(number)} size="sm">
          {number}
        </Button>
      ))}
    </div>
  );
};

// Document Row Component
const DocumentRow = ({ document, onEdit, onDelete, onDownload, formatDate, handlestatus }) => (
  <tr className="border border-primary">
    <td>{document.customer_id}</td>
    <td>{`${document.customer_firstName} ${document.customer_lastName}`}</td>
    <td>{document.company_name}</td>
    <td>{document.project_name}</td>
    <td>{document.document_type}</td>
    <td>{formatDate(document.upload_date)}</td>
    <td>{document.status}</td>
    <td>{document.is_mandatory ? "Yes" : "No"}</td>
    <td>
      <Button variant="primary" size="sm" onClick={() => onEdit(document.document_id)}><MdEdit size={18} /></Button>
      <Button variant="info" size="sm" onClick={() => handlestatus(document.document_id)}><IoMdEye size={18} /></Button>
      <Button variant="danger" size="sm" onClick={() => onDelete(document.document_id)}><MdDelete size={18} /></Button>
    </td>
    <td>
      <Button variant="primary" size="sm" onClick={() => onDownload(document.file_path)}><FaDownload size={16} /></Button>
    </td>
  </tr>
);

export default DocumentsList;
