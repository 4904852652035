import React from "react";
import { Link } from "react-router-dom";

function Admincustomer({ user_id }) {
  if (!user_id) {
    console.error("AdminMenu received an undefined user_id.");
  }
  return (
    <div className="md:h-screen mb-10">
      <div className="admin-menu">
        <h2>Customer Admin</h2>
      </div>
      <div className="list-group">
        <h1  className="list-group-item fs-5">
          Dashboard
        </h1>
        
        <Link to="/admin/addcustomer" className="list-group-item">
          Add Customer
        </Link>
        
        <Link to= {`/admin/viewdocumentbyid/${user_id}`} className="list-group-item">
          View Customer Document
        </Link>
        <Link
          to={`/admin/custom-declarations/${user_id}`}
          className="list-group-item"
        >
          View Custom Document
        </Link>
      </div>
    </div>
  );
}

export default Admincustomer;
