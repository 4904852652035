// Import the auth hook
import { useAuth } from "../../context/AuthContext";
// Import the AddCustomerForm component
import AddCustomerForm from "../../components/customer/AddCustomerForm";
// Import the AdminMenu component
// import AdminMenu from "../../components/AdminMenu/AdminMenu";
import LoginForm from "../../components/Login/LoginForm";
import Admincustomer from "../../components/AdminMenu/Admincustomer";
import Unauthorized from "../../components/Unauthorized/Unauthorized";
function AddCustomer() {
  // Destructure the auth hook
  const { isLogged, isAdmin } = useAuth();

  if (isLogged) {
    if (isAdmin) {
      return (
        <div>
          <div className="container-fluid  d-flex bg-[#F6F7FA]">
            <div className="row">
              <div className="col-md-3 admin-left-side">
                <Admincustomer />
              </div>
              <div className="col-md-9 admin-right-side">
                <AddCustomerForm />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Unauthorized/>
        </div>
      );
    }
  } else {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }
}

export default AddCustomer;
