import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import MobileMenu from "./MobileMenu";
import Sidebar from "./Sidebar";
import navImg from "../../../assets/images/resources/logo.png";
import { useAuth } from "../../../context/AuthContext"; // Import the authentication context
import loginServices from "../../../services/login.services"; // Import the login services

const Header = (props) => {
  const { parentMenu, secondParentMenu, activeMenu } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isSidebar, setIsSidebar] = useState(false);

  // Authentication context
  const { isLogged, setIsLogged, user } = useAuth();
  console.log(useAuth());

  // Handle mobile menu toggle
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    document.body.classList.toggle("mobile-menu-visible", !isMobileMenu);
  };

  // Handle sidebar toggle
  const handleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  // Handle logout
  const handleLogout = () => {
    loginServices.logOut(); // Log out the user
    setIsLogged(false); // Update the authentication context
  };

  // Update header visibility based on scroll
  useEffect(() => {
    const toggleVisibility = () => {
      setIsVisible(window.pageYOffset > 100);
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <React.Fragment>
      <header className={`main-header ${isVisible ? "fixed-header" : ""}`}>
        <nav className="main-menu">
          <div className="main-menu__wrapper">
            <div className="container">
              <div className="main-menu__wrapper-inner">
                <div className="main-menu__left">
                  <div
                    className="main-menu__nav-sidebar-icon"
                    onClick={handleSidebar}
                  >
                    <div className="navSidebar-button">
                      <span className="icon-dots-menu-one" />
                      <span className="icon-dots-menu-two" />
                      <span className="icon-dots-menu-three" />
                    </div>
                  </div>
                  <div className="main-menu__logo">
                    <Link to="/">
                      <img src={navImg} alt="Logo"  width={64} height={10}/>
                    </Link>
                  </div>
                  <div className="main-menu__main-menu-box">
                    <div
                      className="mobile-nav__toggler"
                      onClick={handleMobileMenu}
                    >
                      <i className="fa fa-bars" />
                    </div>
                    <ul className="main-menu__list">
                      <MenuItems
                        parentMenu={parentMenu}
                        secondParentMenu={secondParentMenu}
                        activeMenu={activeMenu}
                      />
                    </ul>
                  </div>
                </div>
                <div className="main-menu__right">
                  {isLogged && user ? (
                    <div className="user-info">
                      <span>Welcome, {user.user_firstName}!</span>
                      <Link
                        to="/"
                        className="thm-btn main-menu__btn"
                        onClick={handleLogout}
                      >
                        Log out
                        <span />
                      </Link>
                    </div>
                  ) : (
                    <div className="main-menu__btn-box">
                      <Link to="/login" className="thm-btn main-menu__btn">
                        Login
                        <span />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div
          className={`stricky-header stricked-menu main-menu ${
            isVisible ? "stricky-fixed" : ""
          }`}
        >
          <div className="" />
          <nav className="main-menu">
            <div className="main-menu__wrapper">
              <div className="container">
                <div className="main-menu__wrapper-inner">
                  <div className="main-menu__left">
                    <div className="main-menu__logo">
                      <Link to="/">
                        <img src={navImg} alt="Logo" width={64} height={10} />
                      </Link>
                    </div>
                    <div className="main-menu__main-menu-box">
                      <div
                        className="mobile-nav__toggler"
                        onClick={handleMobileMenu}
                      >
                        <i className="fa fa-bars" />
                      </div>
                      <ul className="main-menu__list">
                        <MenuItems
                          parentMenu={parentMenu}
                          secondParentMenu={secondParentMenu}
                          activeMenu={activeMenu}
                        />
                      </ul>
                    </div>
                  </div>
                  <div className="main-menu__right">
                    <div className="main-menu__search-nav-sidebar-btn-box">
                      <div
                        className="main-menu__nav-sidebar-icon"
                        onClick={handleSidebar}
                      >
                        <div className="navSidebar-button">
                          <span className="icon-dots-menu-one" />
                          <span className="icon-dots-menu-two" />
                          <span className="icon-dots-menu-three" />
                        </div>
                      </div>
                      {isLogged && user ? (
                        <div className="user-info">
                          <span>Welcome, {user.user_firstName}!</span>
                          <Link
                            to="/"
                            className="thm-btn main-menu__btn"
                            onClick={handleLogout}
                          >
                            Log out
                            <span />
                          </Link>
                        </div>
                      ) : (
                        <div className="main-menu__btn-box">
                          <Link to="/login" className="thm-btn main-menu__btn">
                            Login
                            <span />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>

        <MobileMenu handleMobileMenu={handleMobileMenu} />
      </header>
      <Sidebar isSidebar={isSidebar} handleSidebar={handleSidebar} />
    </React.Fragment>
  );
};

export default Header;
