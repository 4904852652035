
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import userService from "../../services/user.services";
import { useAuth } from "../../context/AuthContext";

function EditUserForm() {
  const { id } = useParams(); // Get the user ID from the URL
  const navigate = useNavigate();
  const [user_firstName, setFirstName] = useState("");
  const [user_lastName, setLastName] = useState("");
  const [user_email, setEmail] = useState("");
  const [role_id, setUserRoleId] = useState(1);
  const [roles, setRoles] = useState([]);
  const [serverError, setServerError] = useState("");
  const { user } = useAuth();
  const loggedInUserToken = user?.user_token || "";

  useEffect(() => {
    // Fetch user data by ID
    userService
      .getUserById(id, loggedInUserToken)
      .then((response) => {
        console.log("User fetch response:", response); // Log the response
        if (response && response.json) {
          return response.json();
        } else {
          throw new Error("Invalid response format");
        }
      })
      .then((data) => {
        if (data.status === "success") {
          const { user_firstName, user_lastName, user_email, role_id } =
            data.data;
          setFirstName(user_firstName || "");
          setLastName(user_lastName  ||"");
          setEmail(user_email  ||"");
          setUserRoleId(role_id || 1);
        } else {
          setServerError(data.error || "Failed to fetch user data");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setServerError("Failed to fetch user data");
      });

    // Fetch roles
    fetchRoles(loggedInUserToken);
  }, [id, loggedInUserToken]);

  const fetchRoles = (token) => {
    userService
      .getRoles(token)
      .then((response) => {
        console.log("Roles fetch response:", response); // Log the response
        if (response && response.json) {
          return response.json();
        } else {
          throw new Error("Invalid response format");
        }
      })
      .then((data) => {
        if (data.status === "success") {
          const rolesData = data.data ||[]; // Ensure response structure matches
          setRoles(rolesData);
          console.log(roles);
        } else {
          setServerError(data.error || "Failed to fetch roles");
        }
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
        setServerError("Failed to fetch roles");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      user_firstName,
      user_lastName,
      user_email,
      role_id,
    };

    userService
      .updateUser(id, formData, loggedInUserToken)
      .then((response) => {
        console.log("Update user response:", response); // Log the response
        if (response && response.json) {
          return response.json();
        } else {
          throw new Error("Invalid response format");
        }
      })
      .then((data) => {
        if (data.status === "success") {
          setServerError("");
          navigate("/admin/userlist");
        } else {
          setServerError(data.error || "Failed to update user");
        }
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        setServerError("Failed to update user");
      });
  };


return (
    <section className="contact-section">
      <div className="auto-container">
        <div className="contact-title">
          <h2>Edit User</h2>
        </div>
        <div className="row clearfix">
          <div className="form-column col-lg-7">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && (
                        <div className="validation-error" role="alert">
                          {serverError}
                        </div>
                      )}
                      <input
                        type="email"
                        name="user_email"
                        value={user_email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="User email"
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="user_firstName"
                        value={user_firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="User first name"
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="user_lastName"
                        value={user_lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="User last name"
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <select
                        name="user_role_id"
                        value={role_id}
                        onChange={(e) => setUserRoleId(Number(e.target.value))}
                        className="custom-select-box"
                      >
                        {roles.length > 0 ? (
                          roles.map((role) => (
                            <option key={role.role_id} value={role.role_id}>
                              {role.Company_role}
                            </option>
                          ))
                        ) : (
                          <option value="">No roles available</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group col-md-12">
                      <button className="theme-btn btn-style-one" type="submit">
                        <span>Update User</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditUserForm;