import { useState, useEffect } from "react";
import { Table, Button, Spinner, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import userService from "../../services/user.services";
import { format } from "date-fns";
import { MdDelete, MdEdit } from "react-icons/md";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ITEMS_PER_PAGE = 10;

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const { user } = useAuth();
  const token = user?.user_token || null;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await userService.getAllUsers(token);
        const data = await response.json();

        if (data.status === "success") {
          setUsers(data.data || []);
        } else {
          throw new Error(data.message || "Error fetching data");
        }
      } catch (err) {
        setApiError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [token]);

  const handleDelete = async (userId) => {
    try {
      const response = await userService.deleteUser(userId, token);
      const data = await response.json();

      if (data.status === "success") {
        setUsers((prevUsers) =>
          prevUsers.filter((usr) => usr.user_id !== userId)
        );
        toast.success("User deleted successfully!");
      } else {
        throw new Error("Failed to delete user");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const formatDate = (dateString) => {
    return dateString ? format(new Date(dateString), "MMMM dd, yyyy") : "N/A";
  };

  const filteredUsers = users.filter(user =>
    user.user_email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);
  const currentUsers = filteredUsers.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <section className="contact-section">
          <div className="auto-container text-center h-screen">
            <Spinner animation="border" />
            <p>Loading users...</p>
          </div>
        </section>
      ) : apiError ? (
        <section className="contact-section">
          <div className="auto-container">
            <h2>{apiError}</h2>
          </div>
        </section>
      ) : (
        <section className="contact-section pb-48">
          <div className="auto-container">
            <h2>Users</h2>
            <Form className="mb-3">
              <Form.Group controlId="searchEmail">
                <Form.Label>Search by Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Form.Group>
            </Form>
            <div className="table-responsive">
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created At</th>
                    <th>Edit/Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map(user => (
                    <motion.tr
                      key={user.user_id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <td>{user.user_firstName}</td>
                      <td>{user.user_lastName}</td>
                      <td>{user.user_email}</td>
                      <td>{user.company_role}</td>
                      <td>{formatDate(user.CreatedAt)}</td>
                      <td>
                        <div className="edit-delete-icons">
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              navigate(`/admin/user/edit/${user.user_id}`)
                            }
                          >
                            <MdEdit />
                          </Button>
                          <Button
                            variant="outline-danger"
                            onClick={() => handleDelete(user.user_id)}
                          >
                            <MdDelete />
                          </Button>
                        </div>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="mx-2">{` Page ${currentPage} of ${totalPages} `}</span>
              <Button
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default UserList;
