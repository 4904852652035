import React from "react";
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons

function AdminDashboard({ user_id }) {
  if (!user_id) {
    console.error("AdminDashboard received an undefined user_id.");
  }

  return (
    <div style={{ backgroundColor: '#f8f9fa', padding: '20px', minHeight: '100vh' }}>
      <div className="admin-menu">
        <h2 style={{ color: '#343a40', fontSize: '2.5rem', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
          Admin Dashboard
        </h2>
      </div>
      <div className="list-group d-flex flex-row">
       
        <Link to="/admin/customers" className="list-group-item d-flex align-items-center" style={linkStyle}>
          <i className="bi bi-person-lines-fill me-2" style={iconStyle}></i>
          <span style={textStyle}>Manage Customers</span>
        </Link>
        <Link to="/admin/addcustomer" className="list-group-item d-flex align-items-center" style={linkStyle}>
          <i className="bi bi-person-plus-fill me-2" style={iconStyle}></i>
          <span style={textStyle}>Add Customer</span>
        </Link>
        <Link to="/admin/add-document" className="list-group-item d-flex align-items-center" style={linkStyle}>
          <i className="bi bi-file-earmark-plus me-2" style={iconStyle}></i>
          <span style={textStyle}>Add Document</span>
        </Link>
        <Link to="/admin/view-document" className="list-group-item d-flex align-items-center" style={linkStyle}>
          <i className="bi bi-file-earmark-text me-2" style={iconStyle}></i>
          <span style={textStyle}>View Customer Documents</span>
        </Link>
        <Link to={`/admin/custom-declarations/${user_id}`} className="list-group-item d-flex align-items-center" style={linkStyle}>
          <i className="bi bi-file-earmark-post me-2" style={iconStyle}></i>
          <span style={textStyle}>View Custom Document</span>
        </Link>
        
      </div>
    </div>
  );
}

const linkStyle = {
  backgroundColor: '#ffffff',
  color: '#007bff', // Blue text color
  border: '1px solid #ced4da',
  marginBottom: '10px',
  transition: 'background-color 0.3s',
  padding: '15px', // Increased padding for better spacing
};

const iconStyle = {
  fontSize: '4.5rem', // Icon size
  color: '#007bff', // Blue color for icons
};

const textStyle = {
  fontSize: '1.5rem', // Increased font size for links
  fontFamily: 'Arial, sans-serif', // Font family for text
  fontWeight: 'normal', // Normal font weight
  marginLeft: '10px', // Space between icon and text
};

export default AdminDashboard;
