import { Link } from 'react-router-dom';
import { useState } from 'react';
import loginServices from "../../../services/login.services"; // Import the login services
import logo from '../../../assets/images/resources/logo.png';
import { useAuth } from "../../../context/AuthContext"; // Import the authentication context
import { FaTimes, FaEnvelope, FaPhoneAlt, FaTwitter, FaFacebookSquare, FaPinterestP, FaInstagram } from 'react-icons/fa'; // Importing React Icons

const MobileMenu = ({ handleMobileMenu }) => {
  const { isLogged, setIsLogged, user } = useAuth();
  const handleLogout = () => {
    loginServices.logOut(); // Log out the user
    setIsLogged(false); // Update the authentication context
  };

  const [isActive, setIsActive] = useState({
    status: false,
    key: '',
    subMenuKey: '',
  });

  const handleToggle = (key, subMenuKey = '') => {
    if (isActive.key === key && isActive.subMenuKey === subMenuKey) {
      setIsActive({
        status: false,
        key: '',
        subMenuKey: '',
      });
    } else {
      setIsActive({
        status: true,
        key,
        subMenuKey,
      });
    }
  };

  const handleLinkClick = (e) => {
    // Prevent closing the menu when clicking on email or phone number links
    if (!e.target.closest('.mobile-nav__contact')) {
      handleMobileMenu();
    }
  };

  return (
    <>
      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler" onClick={handleMobileMenu}></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler" onClick={handleMobileMenu}>
            <FaTimes /> {/* Replace fa-times with React Icon */}
          </span>

          <div className="logo-box">
            <Link to="/" aria-label="logo image">
              <img src={logo} width={64} height={10} alt="Logo" />
            </Link>
          </div>

          {/* Email and phone number section, always visible */}
          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <FaEnvelope /> {/* React Icon for email */}
              <Link to="/" onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <FaEnvelope />
              <Link to="/about" onClick={handleLinkClick}>About</Link>
            </li>
            <li>
              <FaEnvelope />
              <Link to="/services" onClick={handleLinkClick}>Service</Link>
            </li>
            <li>
              <FaEnvelope />
              <Link to="/project" onClick={handleLinkClick}>Project</Link>
            </li>
            <li>
              <FaEnvelope />
              <Link to="/Contact" onClick={handleLinkClick}>Contact</Link>
            </li>
            
            <div className="">
              { user ? (
                <div className="user-info">
                  <span>Welcome, {user.user_firstName}!</span>
                  <Link
                    to="/"
                    className="thm-btn main-menu__btn"
                    
                  >
                    Log out
                    <span />
                  </Link>
                </div>
              ) : (
                <div className="main-menu__btn-box">
                  <Link to="/login" className="thm-btn main-menu__btn">
                    Login
                    <span />
                  </Link>
                </div>
              )}
            </div>

            <li>
              <FaEnvelope />
              <Link to="mailto:gmnetwork21@gmail.com">gmnetwork21@gmail.com</Link>
            </li>
            <li>
              <FaPhoneAlt />
              <Link to="tel:+251911094804">+251911094804</Link>
            </li>
          </ul>

          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <Link to="#" className=""><FaTwitter /></Link>
              <Link to="#" className=""><FaFacebookSquare /></Link>
              <Link to="#" className=""><FaPinterestP /></Link>
              <Link to="#" className=""><FaInstagram /></Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
