import { useState } from "react";
import customDeclarationService from "../../services/customDeclaration.services";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function AddCustomForm({ customerId }) {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  console.log("Customer ID:", customerId); // Debugging line

  const { user } = useAuth();
  const loggedInUserToken = user?.token;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form
    let valid = true;
    const newErrors = {};

    if (!fileName) {
      newErrors.fileName = "File name is required";
      valid = false;
    }

    if (!file) {
      newErrors.file = "File is required";
      valid = false;
    } else if (
      file.size > 5 * 2048 * 2048 || // 5MB limit
      !["application/pdf", "application/msword"].includes(file.type)
    ) {
      newErrors.file = "File must be a PDF or Word document and less than 5MB";
      valid = false;
    }

    setErrors(newErrors);

    if (!valid) return;

    const formData = new FormData();
    formData.append("user_id", user.user_id); 
    formData.append("file_name", fileName);
    formData.append("file", file);
    formData.append("customer_id", customerId); 
   
    // Ensure this is correct

    setIsLoading(true);

    try {
      const response = await customDeclarationService.createCustomDeclaration(
        formData,
        loggedInUserToken
      );

      if (!response.ok) {
        const errorText = await response.text();
        setServerError(`Error! Status: ${response.status}, Message: ${errorText}`);
        return;
      }

      const data = await response.json();

      if (data.status === "true") {
        setSuccess(true);
        setServerError("");
        setFileName("");
        setFile(null);

        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setServerError("Failed to add custom declaration. Please try again.");
      }
    } catch (error) {
      setServerError(`An error occurred while adding the custom declaration: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact-section">
      <div className="auto-container">
        <div className="contact-title">
          <h2>Add a new custom declaration</h2>
        </div>
        <div className="row clearfix">
          <div className="form-column col-lg-7">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && (
                        <div className="validation-error" role="alert">
                          {serverError}
                        </div>
                      )}
                      <input
                        type="text"
                        name="file_name"
                        value={fileName}
                        onChange={(event) => setFileName(event.target.value)}
                        placeholder="File name"
                        disabled={isLoading}
                      />
                      {errors.fileName && (
                        <div className="validation-error" role="alert">
                          {errors.fileName}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => setFile(event.target.files[0])}
                        disabled={isLoading}
                      />
                      {errors.file && (
                        <div className="validation-error" role="alert">
                          {errors.file}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <button
                        type="submit"
                        className="theme-btn btn-style-one"
                        disabled={isLoading}
                      >
                        {isLoading ? "Uploading..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddCustomForm;
