import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import loginService from "../../services/login.services";
import { useAuth } from "../../context/AuthContext";
import userService from '../../services/user.services'

function LoginForm() {
  const location = useLocation();
  const [user_email, setEmail] = useState("");
  const [user_password_value, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serverError, setServerError] = useState("");
  const {user}=useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
  
    // Email validation
    if (!user_email) {
      setEmailError("Please enter your email address first");
      valid = false;
    } else if (!user_email.includes("@")) {
      setEmailError("Invalid email format");
      valid = false;
    } else {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(user_email)) {
        setEmailError("Invalid email format");
        valid = false;
      } else {
        setEmailError("");
      }
    }
  
    // Password validation
    if (!user_password_value || user_password_value.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }
  
    if (!valid) {
      return;
    }
  
    const formData = {
      user_email,
      user_password_value,
    };
  
    try {
      const response = await loginService.logIn(formData);
      const data = await response.json();
      console.log(data);
  
      if (data.status === "success") {
        if (data.data.user_token) {
          localStorage.setItem("user", JSON.stringify(data.data));
        }
  
        // Ensure user object is available and check for user_role
        if ((user && user?.user_id)) {
          if ((user?.user_id) === 4) {
            window.location.replace("/admin/user");
          } else {
            window.location.replace("/admin/addcustomer");
          }
        } else {
          // Handle case where user or user_role is undefined/null
          console.error("User role is missing or user is not authenticated");
          setServerError("User role information is missing.");
        }
      } else {
        setServerError(data.message);
      }
    } catch (err) {
      console.log(err);
      setServerError("An error has occurred. Please try again later." + err);
    }
  };
  

  return (
    <section className="contact-section d-flex align-items-center justify-content-center h-screen" >
      <div className="auto-container">
        <div className="contact-title text-center mb-4">
          <h2>Login to Your Account</h2>
        </div>
        <div className="row justify-content-center">
          <div className="form-column col-lg-10">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && (
                        <div className="validation-error" role="alert">
                          {serverError}
                        </div>
                      )}
                      <input
                        type="email"
                        name="user_email"
                        value={user_email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="Email"
                        className="form-control custom-input"
                      />
                      {emailError && (
                        <div className="validation-error" role="alert">
                          {emailError}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        type="password"
                        name="user_password_value"
                        value={user_password_value}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Password"
                        className="form-control custom-input"
                      />
                      {passwordError && (
                        <div className="validation-error" role="alert">
                          {passwordError}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <button
                        className="theme-btn btn-style-one w-100 custom-button"
                        type="submit"
                      >
                        <span>Login</span>
                        
                      </button>
                      
                    </div>
                    <button>need an account <Link to="/register" className="text-red-500"> click Register</Link></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
