import { useState } from "react";
import customerService from "../../services/customer.services"; // Adjust the path as necessary
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function AddCustomerForm(props) {
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAuth();
  const loggedInCustomerToken = user?.token || "";
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle client-side validations
    let valid = true;

    // Validation for first name
    if (!customerFirstName) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      valid = false;
    } 
    else if (customerFirstName.length<3) {
      setErrors((prev) => ({ ...prev, firstName: "First name is must be at least three character" }));
      valid = false;
    } 

    else {
      setErrors((prev) => ({ ...prev, firstName: "" }));
    }

    // Validation for last name
    if (!customerLastName) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      valid = false;
    }
    else if (customerLastName.length<3) {
      setErrors((prev) => ({ ...prev, lastName: "Last name must be at least three character" }));
      valid = false;
    } else {
      setErrors((prev) => ({ ...prev, lastName: "" }));
    }

    // Validation for email
    if (!customerEmail) {
      setErrors((prev) => ({ ...prev, email: "Email is required" }));
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(customerEmail)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
      valid = false;
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }

    // Validation for phone number
    if (!customerPhone) {
      setErrors((prev) => ({ ...prev, phone: "Phone number is required" }));
      valid = false;
    }
    else if (customerPhone.length<9) {
      setErrors((prev) => ({ ...prev, phone: "please enter full phone number" }));
      valid = false;
    }
     else {
      setErrors((prev) => ({ ...prev, phone: "" }));
    }

    // Validation for company name
    if (!companyName) {
      setErrors((prev) => ({ ...prev, company: "Company name is required" }));
      valid = false;
    } 
    else if (companyName.length<3) {
      setErrors((prev) => ({ ...prev, company: "Company name must be at least three chracters" }));
      valid = false;
    }
    else {
      setErrors((prev) => ({ ...prev, company: "" }));
    }

    // Validation for address
    if (!address) {
      setErrors((prev) => ({ ...prev, address: "Address is required" }));
      valid = false;
    } 
    else if (address.length<3) {
      setErrors((prev) => ({ ...prev, address: "Address must be at least three character" }));
      valid = false;
    }
    else {
      setErrors((prev) => ({ ...prev, address: "" }));
    }

    // If the form is not valid, do not submit
    if (!valid) return;

    const formData = {
      user_id: user.user_id,
      customer_firstName: customerFirstName || null,
      customer_lastName: customerLastName || null,
      company_name: companyName || null,
      address: address || null,
      customer_email: customerEmail || null,
      customer_phone: customerPhone || null,
    };

    setIsLoading(true);

    try {
      const response = await customerService.createCustomer(
        formData,
        loggedInCustomerToken
      );
      const data = await response.json();
      console.log("Parsed Response Data:", data);
      if (data.status === "true") {
        const customerId = data.customer_id; // Assuming the backend returns customer_id
        console.log("value of customer ", customerId);

        setSuccess(true);
        setServerError("");

        // Clear form fields
        setCustomerEmail("");
        setCustomerFirstName("");
        setCustomerLastName("");
        setCustomerPhone("");
        setCompanyName("");
        setAddress("");

        // Navigate to the document upload page with the customerId
        setTimeout(() => {
          navigate(`/admin/addcustomer/${customerId}`);
        }, 2000);
      } else {
        setServerError("Failed to add customer. Please try again.");
      }
    } catch (error) {
      setServerError("An error occurred while adding the customer.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact-section mb-10 mx-auto">
      <div className="auto-container">
        <div className="contact-title">
          <h2>Add a new customer</h2>
        </div>
        <div className="row clearfix">
          <div className="form-column col-lg-7">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && (
                        <div className="validation-error" role="alert">
                          {serverError}
                        </div>
                      )}
                      <input
                        type="email"
                        name="customer_email"
                        value={customerEmail}
                        className="rounded-3"
                        onChange={(event) =>
                          setCustomerEmail(event.target.value)
                        }
                        placeholder="Company email"
                        disabled={isLoading}
                      />
                      {errors.email && (
                        <div className="validation-error" role="alert">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="customer_firstName"
                        className="rounded-3"
                        value={customerFirstName}
                        onChange={(event) =>
                          setCustomerFirstName(event.target.value)
                        }
                        placeholder="Customer first name"
                        disabled={isLoading}
                      />
                      {errors.firstName && (
                        <div className="validation-error" role="alert">
                          {errors.firstName}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="rounded-3"
                        name="customer_lastName"
                        value={customerLastName}
                        onChange={(event) =>
                          setCustomerLastName(event.target.value)
                        }
                        placeholder="Customer last name"
                        disabled={isLoading}
                      />
                      {errors.lastName && (
                        <div className="validation-error" role="alert">
                          {errors.lastName}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="company_name"
                        className="rounded-3"
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                        placeholder="Company name"
                        disabled={isLoading}
                      />
                      {errors.company && (
                        <div className="validation-error" role="alert">
                          {errors.company}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="address"
                        className="rounded-3"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        placeholder="Address"
                        disabled={isLoading}
                      />
                      {errors.address && (
                        <div className="validation-error" role="alert">
                          {errors.address}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="customer_phone"
                        className="rounded-3"
                        value={customerPhone}
                        onChange={(event) =>
                          setCustomerPhone(event.target.value)
                        }
                        placeholder="Customer phone (555-555-5555)"
                        disabled={isLoading}
                      />
                      {errors.phone && (
                        <div className="validation-error" role="alert">
                          {errors.phone}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        data-loading-text="Please wait..."
                        disabled={isLoading}
                      >
                        {isLoading ? "Adding..." : <span>Add customer</span>}
                      </button>
                    </div>
                  </div>
                </form>
                {success && (
                  <div className="alert alert-success" role="alert">
                    Customer added successfully! Redirecting...
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddCustomerForm;
