// src/components/ServiceItem.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ServiceItem = ({ iconClass, title, link, description }) => {
    // State to control the visibility of the description
    const [isDescriptionVisible, setDescriptionVisible] = useState(false);

    // Toggle the description visibility
    const handleToggleDescription = () => {
        setDescriptionVisible(prevState => !prevState);
    };

    return (
        <div className="services-one__single">
            <div className="services-one__icon">
                <span className={iconClass} />
            </div>
            <h3 className="services-one__title">
                <Link to={link}>
                    {title}
                </Link>
            </h3>

            {/* Conditionally render the description */}
            {isDescriptionVisible && (
                <p className="services-one__description">
                    {description}
                </p>
            )}

            <div className="services-one__btn-box">
                <button onClick={handleToggleDescription} className="thm-btn services-one__btn">
                    {isDescriptionVisible ? 'Read less' : 'Read more'}
                    <span />
                </button>
            </div>
        </div>
    );
};

export default ServiceItem;
