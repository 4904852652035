import EditUserForm from "../../components/EditUser/EditUserForm";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import { useParams } from "react-router-dom";

function EditUser() {
  const { id } = useParams(); // Get the user ID from the URL

  return (
    <div>
      <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
            <EditUserForm id={id} /> {/* Pass the ID to EditUserForm */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
