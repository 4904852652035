import { useState } from "react";
import { motion } from "framer-motion";
import userService from "../../services/user.services";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineEye as Eye, AiOutlineEyeInvisible as EyeOff } from 'react-icons/ai';

function RegisterUserForm() {
  const [user_firstName, setFirstName] = useState("");
  const [user_lastName, setLastName] = useState("");
  const [user_email, setEmail] = useState("");
  const [user_password_value, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { user } = useAuth();
  const loggedInUserToken = user?.token || "";
  const navigate = useNavigate();

  const validateEmail = (email) => /^\S+@\S+\.\S+$/.test(email);
  const validatePassword = (password) =>
    password.length >= 8 &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[!@#$%^&*(),.?":{}|<>]/.test(password);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    setEmailError(user_email ? (validateEmail(user_email) ? "" : "Invalid email format") : "Email is required");
    setPasswordError(user_password_value ? (validatePassword(user_password_value) ? "" : "Password must meet requirements") : "Password is required");
    setFirstNameError(user_firstName ? "" : "First name is required");
    setLastNameError(user_lastName ? "" : "Last name is required");
    setServerError(termsAccepted ? "" : "You must accept the terms and conditions");

    if (!user_email || !validateEmail(user_email) || !user_password_value || !validatePassword(user_password_value) || !user_firstName || !user_lastName || !termsAccepted) {
      valid = false;
    }

    if (!valid) return;

    setLoading(true);
    const formData = { user_firstName, user_lastName, user_email, user_password_value };

    try {
      const response = await userService.createUser(formData, loggedInUserToken);
      const data = await response.json();

      if (data.status === "success") {
        localStorage.setItem("user", JSON.stringify(data.data));
        navigate("/admin/addcustomer");
      } else {
        setServerError(data.message || "Failed to process request");
      }
    } catch (error) {
      setServerError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="auth-section h-screen mb-48 flex items-center justify-center bg-gray-100">
      <motion.div
        className="auth-container w-full max-w-[50%] p-8 bg-white rounded-lg shadow-md"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">Create a New Account</h2>
        {serverError && <div className="validation-error text-red-500 mb-4">{serverError}</div>}
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1 text-gray-600">Email</label>
            <input
              type="email"
              className={`w-full p-3 border-2 rounded-lg transition duration-200 ${emailError ? "border-red-500" : "border-gray-300 hover:border-gray-400 focus:border-blue-500 focus:outline-none"}`}
              value={user_email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Enter your email"
            />
            {emailError && <div className="validation-error text-red-500">{emailError}</div>}
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-gray-600">First Name</label>
            <input
              type="text"
              className={`w-full p-3 border-2 rounded-lg transition duration-200 ${firstNameError ? "border-red-500" : "border-gray-300 hover:border-gray-400 focus:border-blue-500 focus:outline-none"}`}
              value={user_firstName}
              onChange={(event) => setFirstName(event.target.value)}
              placeholder="Enter your first name"
            />
            {firstNameError && <div className="validation-error text-red-500">{firstNameError}</div>}
          </div>

          <div className="mb-4">
            <label className="block mb-1 text-gray-600">Last Name</label>
            <input
              type="text"
              className={`w-full p-3 border-2 rounded-lg transition duration-200 ${lastNameError ? "border-red-500" : "border-gray-300 hover:border-gray-400 focus:border-blue-500 focus:outline-none"}`}
              value={user_lastName}
              onChange={(event) => setLastName(event.target.value)}
              placeholder="Enter your last name"
            />
            {lastNameError && <div className="validation-error text-red-500">{lastNameError}</div>}
          </div>

          <div className="mb-4 relative">
            <label className="block mb-1 text-gray-600">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className={`w-full p-3 border-2 rounded-lg transition duration-200 ${passwordError ? "border-red-500" : "border-gray-300 hover:border-gray-400 focus:border-blue-500 focus:outline-none"}`}
              value={user_password_value}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="Enter your password"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-2 flex items-center"
              onClick={() => setShowPassword(!showPassword)}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? <EyeOff /> : <Eye />}
            </button>
            {passwordError && <div className="validation-error text-red-500">{passwordError}</div>}
          </div>

          <div className="mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                className="mr-2"
              />
              I agree to the <Link to="/terms" className="">terms and conditions</Link>
            </label>
            {!termsAccepted && <div className="validation-error text-red-500">{serverError}</div>}
          </div>

          <button
            className="w-full p-3 bg-[#C11425] text-white font-semibold rounded-lg hover:bg-[#0F1B24] transition duration-200"
            type="submit"
            disabled={loading}
          >
            {loading ? <span className="loader"></span> : "Register"}
          </button>
        </form>

        <p className="text-center mt-4">
          Already have an account? <Link to="/login" className="text-red-500">Click here to login</Link>
        </p>
      </motion.div>
    </section>
  );
}

export default RegisterUserForm;
