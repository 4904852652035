import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/resources/logo.png'; // Import your logo image here

const Sidebar = ({ isSidebar, handleSidebar }) => {
    return (
        <div className={`xs-sidebar-group info-group info-sidebar ${isSidebar ? "isActive" : "close-sidebar"}`}>
            <div className="xs-overlay xs-bg-black" onClick={handleSidebar} />
            <div className="xs-sidebar-widget">
                <div className="sidebar-widget-container">
                    <div className="widget-heading">
                        <span className="close-side-widget" onClick={handleSidebar}>X</span>
                    </div>
                    <div className="sidebar-textwidget">
                        <div className="sidebar-info-contents">
                            <div className="content-inner">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logo}  width={64} height={10} alt="Logo" />
                                    </Link>
                                </div>
                                <div className="content-box">
                                    <h4>About Us</h4>
                                    <p>At <span className='text-blue-500'>Tarikawit GebreEyesus,</span> we provide exceptional logistics customer service to keep your supply chain running smoothly. Our dedicated team offers 24/7 support, real-time shipment tracking, and proactive communication to address any issues. Whether you need assistance with delivery scheduling, inventory management, or problem resolution, we’re here to help. We tailor our services to meet your unique needs, ensuring fast, reliable, and efficient solutions. Trust us to optimize your logistics and provide a seamless experience from start to finish. Contact us today to learn more!.</p>
                                </div>
                                <div className="form-inner">
                                    <h4>Get a free quote</h4>
                                    <form action="" className="contact-form-validated" noValidate="novalidate">
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder="Name" required="" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="email" placeholder="Email" required="" />
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" placeholder="Message..."></textarea>
                                        </div>
                                        <div className="form-group message-btn">
                                            <button type="submit" className="thm-btn form-inner__btn">Submit Now</button>
                                        </div>
                                    </form>
                                    <div className="result" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
