import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddDocumentForm from "../../components/Document/AddDocument";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import customerService from "../../services/customer.services";
import { useAuth } from "../../context/AuthContext";

function AddCustomer() {
  const { customerId } = useParams(); // Get customerId from route params
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const loggedInUserToken = user?.token || "";

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        // Fetch customer details using the customerId
        const customerResponse = await customerService.getCustomerById(
          customerId,
          loggedInUserToken
        );
        if (!customerResponse.ok) {
          throw new Error("Error fetching customer details");
        }
        const customerData = await customerResponse.json();
        setCustomer(customerData.data); // Assuming the data comes under `data`
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId, loggedInUserToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          {customer ? (
            <div>
              <h3>Customer Details</h3>
              <p>Email: {customer.customer_email}</p>
              <p>Phone: {customer.customer_phone}</p>

              <button>Edit Customer Info</button>
            </div>
          ) : (
            <div>No customer details found.</div>
          )}

          <h3>Add Document</h3>
          {/* Pass customerId as a prop to AddDocumentForm */}
          <AddDocumentForm customerId={customerId} />
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
