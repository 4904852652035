import React, { useEffect, useState } from 'react';
import BackToTop from '../../components/elements/BackToTop';

import navImg1 from '../../assets/images/resources/logo-1.png';
// import Footer from '../../components/Layout/Footer';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/images/backgrounds/banner-6.gif';
import CtaSection from '../../components/Common/CtaSection';
import shapeImageUrl from '../../assets/images/shapes/cta-one-shape-1.png';
import AboutMain from './AboutMain';


// Breadcrumbs Background Image

const About = () => {

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <React.Fragment>
       
        {/* SiteBreadcrumb */}
        <SiteBreadcrumb
            pageTitle="About US"
            pageName="About US"
            breadcrumbsImg={bannerbg}
        />
        {/* SiteBreadcrumb */}
        <AboutMain/>
        {/*cta-section */}
        <CtaSection
        className="cta-one cta-two" // Custom class name
        breadcrumbsImg={bannerbg}
        shapeImageUrl={shapeImageUrl}
        title="Need any help? contact us!"
        text="Need help?"
        phoneNumber="(+251)911094804"
        />
        {/*cta-section end */}
        <BackToTop scroll={isVisible} />
        {/* <Footer /> */}
    </React.Fragment>
    );
}

export default About;