import React from 'react';
import ProjectCard from '../../components/Project/ProjectCard';
import projectImg1 from '../../assets/images/project/logisticproject.jpg';
import projectImg2 from '../../assets/images/project/logisticsproject1.jpg';
import projectImg3 from '../../assets/images/project/logisticproject2.jpg';
import projectImg4 from '../../assets/images/project/logisticproject.jpg';
import projectImg5 from '../../assets/images/project/logisticproject2.jpg';

const Project = ( ) => {
    // Example data
    const projectsData = [
        {
            id: 1,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            content: 'Seamless logistics for your logo',
            link: '/'
        },
        {
            id: 2,
            image: projectImg1,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            link: '/'
        },
        {
            id: 3,
            image: projectImg2,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            link: '/'
        },
        {
            id: 4,
            image: projectImg3,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            link: '/'
        },
        {
            id: 5,
            image: projectImg4,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            link: '/'
        },
        {
            id: 6,
            image: projectImg5,
            category: 'Express Logistics',
            title: 'Delivering success through logistics custom Clearance services',
            link: '/'
        },
        // Add more project data here as needed
    ];
    
    return (
        <>
            {/*Project Two Start*/}
            <ProjectCard projects={projectsData} />
            {/*Project Two End*/}
        </>
    );
}

export default Project;
