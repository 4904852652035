import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Alert, Form, Pagination } from "react-bootstrap"; // Added Pagination here
import { format, parseISO } from "date-fns";
import { useAuth } from "../../context/AuthContext";
import customDeclarationService from "../../services/customDeclaration.services";
import { MdDelete } from "react-icons/md";
import { FaDownload } from "react-icons/fa";

const CustomDeclarationsList = () => {
  const [customDeclarations, setCustomDeclarations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newDeclaration, setNewDeclaration] = useState({ file_name: '', file_path: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");

  const { user } = useAuth();
  const token = ""; // Add actual token handling here

  useEffect(() => {
    const fetchCustomDeclarations = async () => {
      if (!user.user_id) {
        setApiError(true);
        setApiErrorMessage("Customer ID is missing.");
        setLoading(false);
        return;
      }

      try {
        const response = await customDeclarationService.getCustomDeclarationsByCustomerId(user.user_id, token);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error: ${response.status} ${response.statusText} - ${errorText}`);
        }
        const data = await response.json();
        setCustomDeclarations(data);
      } catch (error) {
        setApiError(true);
        // setApiErrorMessage(error.message || "An error occurred while fetching data.");
        console.error("Error fetching custom declarations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomDeclarations();
  }, [token, user.user_id]);

  const handleAddCustomDeclaration = async () => {
    setIsSubmitting(true);
    try {
      const response = await customDeclarationService.createCustomDeclaration(newDeclaration, token);
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to add custom declaration: ${errorText}`);
      }
      const result = await response.json();
      setCustomDeclarations((prev) => [...prev, result]);
      setSuccessMessage("Custom Declaration added successfully!");
      setNewDeclaration({ file_name: '', file_path: '' });
    } catch (error) {
      setApiError(true);
      // setApiErrorMessage(error.message || "An error occurred while adding the custom declaration.");
      console.error("Error adding custom declaration:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await customDeclarationService.deleteCustomDeclaration(id, token);
      if (response.ok) {
        setCustomDeclarations((prevDeclarations) => prevDeclarations.filter(declaration => declaration.declaration_id !== id));
        setSuccessMessage("Custom Declaration deleted successfully!");
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to delete custom declaration: ${errorText}`);
      }
    } catch (error) {
      setApiError(true);
      // setApiErrorMessage(error.message || "An error occurred while deleting the custom declaration.");
      console.error("Error deleting custom declaration:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = parseISO(dateString);
    return isNaN(date.getTime()) ? "Invalid Date" : format(date, "MMMM /dd/yyyy");
  };

  const handleDownload = (file_path) => {
    if (!file_path) {
      alert("File path is not provided!");
      return;
    }
    window.open(`http://localhost:3003/${file_path}`, "_blank");
  };

  const indexOfLastDeclaration = currentPage * itemsPerPage;
  const indexOfFirstDeclaration = indexOfLastDeclaration - itemsPerPage;
  
  const filteredDeclarations = customDeclarations.filter(declaration =>
    declaration.file_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentDeclarations = filteredDeclarations.slice(indexOfFirstDeclaration, indexOfLastDeclaration);
  const totalPages = Math.ceil(filteredDeclarations.length / itemsPerPage);

  return (
    <section className="custom-declaration-section">
      {loading ? (
        <div className="auto-container text-center">
          <Spinner animation="border" />
          <p>Loading custom declarations...</p>
        </div>
      ) : apiError ? (
        <div className="auto-container">
          <div className="declaration-title">
            <h2>{apiErrorMessage}</h2>
          </div>
        </div>
      ) : (
        <div className="auto-container h-screen">
          <div className="declaration-title">
            <h2>Custom Declarations</h2>
          </div>
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          
          <Form inline className="mb-3">
            <Form.Control 
              type="text" 
              placeholder="Search by Project Name" 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)} 
              className="mr-sm-2" 
            />
            <Button onClick={() => setCurrentPage(1)}>Search</Button>
          </Form>

          <div className="table-scroll-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Project Name</th>
                  <th>Upload Date</th>
                  <th>Delete</th>
                  <th>View Custom Document</th>
                </tr>
              </thead>
              <tbody>
                {currentDeclarations.length > 0 ? (
                  currentDeclarations.map((declaration) => (
                    <tr key={declaration.declaration_id}>
                      <td className="text-center">{declaration.customer_firstName} {declaration.customer_lastName}</td>
                      <td className="text-center">{declaration.file_name}</td>
                      <td className="text-center">{formatDate(declaration.upload_date)}</td>
                      <td className="text-center">
                        <Button variant="danger" onClick={() => handleDelete(declaration.declaration_id)}><MdDelete /></Button>
                      </td>
                      <td className="text-center">
                        <Button className="text-btn-center" variant="primary" onClick={() => handleDownload(declaration.file_path)}><FaDownload /></Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">No custom declarations found.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div className="pagination-container">
            <Pagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      )}
    </section>
  );
};

export default CustomDeclarationsList;
