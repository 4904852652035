import React from 'react';
import WhyAreWe from '../../components/About/WhyAreWe';
import image1 from '../../assets/images/backgrounds/Untitled-1.gif';
import image2 from '../../assets/images/backgrounds/implment.gif';

const WhyAreWeSec = () => {
    const points = [
        {
            iconClass: 'why-are-we__icon',
            arrowIcon: 'icon-arrow-down-left',
            icon: 'icon-location why-are-we__location',
            heading: 'Seamless Customs Clearance',
            text: 'Efficient handling of all customs documentation and processes, ensuring quick clearance and compliance with local regulations.'
        },
        {
            iconClass: 'why-are-we__icon-2',
            arrowIcon: 'icon-arrow-down-right',
            icon: 'icon-clock why-are-we__clock',
            heading: 'On-Time Delivery',
            text: 'We ensure your goods are delivered on time, with efficient clearance and customs processing at every step of the journey.'
        },
        {
            iconClass: '',
            arrowIcon: '',
            icon: 'icon-call why-are-we__call',
            heading: '24/7 Support for Customs Queries',
            text: 'Our dedicated support team is available around the clock to address any customs-related queries and provide real-time updates.'
        }
    ];

    return (
        <>
            <WhyAreWe
                imageSrc1={image1}
                imageSrc2={image2}
                tagline="Why Choose Us for Customs Clearance?"
                title="Your Trusted Partner for Efficient Customs Clearance Logistics"
                points={points}
                year="Since 1920"
                yearDescription="We specialize in the planning, implementation, and management of customs clearance logistics, ensuring the smooth flow of goods across borders."
            />
        </>
    );
}

export default WhyAreWeSec;
