import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import documentService from "../../services/document.services";
import { useAuth } from "../../context/AuthContext";

function EditStatus() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("pending"); // Default to "approved"
  const [serverError, setServerError] = useState("");
  const { user } = useAuth();
  const loggedInUserToken = user?.user_token || "";

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await documentService.getDocumentStatusById(id, loggedInUserToken);
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Error: ${response.status} ${errorMessage}`);
        }
        const data = await response.json();
        if (data.status === "success") {
          setStatus(data.data.status || "approved");
        } else {
        
        }
      } catch (error) {
        console.error("Error fetching document data:", error);
        
      }
    };

    fetchDocumentData();
  }, [id, loggedInUserToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { status };

    try {
      const response = await documentService.updateDocument(id, formData, loggedInUserToken);
      const data = await response.json();
      console.log("fetch status ",data)
      navigate("/admin/view-document");
      
    } catch (error) {
      console.error("Error updating status:", error);
      // setServerError("Failed to update status");
    }
  };

  return (
    <section className="contact-section">
      <div className="auto-container">
        <div className="contact-title">
          <h2>Edit Document Status</h2>
        </div>
        <div className="row clearfix">
          <div className="form-column col-lg-7">
            <div className="inner-column">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="row clearfix">
                    <div className="form-group col-md-12">
                      {serverError && (
                        <div className="validation-error" role="alert">
                          {serverError}
                        </div>
                      )}
                      <select
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="custom-select-box"
                      >
                        <option value="approved">Approved</option>
                        <option value="on progress">On Progress</option>
                        <option value="completed">Completed</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    </div>
                    <div className="form-group col-md-12">
                      <button className="theme-btn btn-style-one" type="submit">
                        <span>Update Status</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditStatus;
