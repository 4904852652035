import React from "react";
import { useAuth } from "../../context/AuthContext";
import LoginForm from "../../components/Login/LoginForm";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import CustomDeclarationsList from "../../components/CustomList/CustomList"; // Ensure this component handles the customer_id prop
import { useParams } from "react-router-dom";
import AdminCustomList from "../../components/CustomList/AdminCustomList";
import Unauthorized from "../../components/Unauthorized/Unauthorized";


function AdminCustomList21() {
  // Destructure the auth hook
  const { isLogged, isAdmin, user} = useAuth();
  // Get customer_id from URL parameters
  // const { user_id } = useParams();
  console.log`("Customer ID from URL:", {user.user_id})`;
  if (!isLogged) {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div>
        <Unauthorized/>
      </div>
    );
  }

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          {/* Pass customer_id to AdminMenu component */}
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          {/* Pass customer_id to CustomDeclarationsList component */}
          <AdminCustomList/>
        </div>
      </div>
    </div>
  );
}

export default AdminCustomList21;
