const API_URL = "http://localhost:3003";

// Function to create a new document
const createDocument = async (documentData, loggedInCustomerToken) => {
  const options = {
    method: "POST",
    headers: {
      "x-access-token": loggedInCustomerToken,
      // Do not set Content-Type here when using FormData
    },
    body: documentData, // `documentData` is a FormData object
  };

  const response = await fetch(`${API_URL}/api/documents`, options);
  return response;
};

// Function to get all documents
const getAllDocuments = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(`${API_URL}/api/documents`, requestOptions);
  return response;
};


const getDocumentStatusById = async (id, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/documentstatus/${id}`,
    requestOptions
  );
  return response;
};
// Function to get a document by ID

const getDocumentById = async (id, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/documents/${id}`,
    requestOptions
  );
  return response;
};

// Function to update a document
const updateDocument = async (id, documentData, token) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(documentData),
  };

  const response = await fetch(`${API_URL}/api/documents/${id}`, options);
  return response;
};

// Function to delete a document
const deleteDocument = async (id, token) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/documents/${id}`,
    requestOptions
  );
  return response;
};

// Export all the functions
const documentService = {
  createDocument,
  getAllDocuments,
  getDocumentById,
  updateDocument,
  deleteDocument,
  getDocumentStatusById
};

export default documentService;
