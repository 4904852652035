import React, { useEffect, useState } from 'react';
import BackToTop from '../../components/elements/BackToTop';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/images/backgrounds/logistics-3.gif';
// import Footer from '../../components/Layout/Footer';
import ContactMain from './ContactMain';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const Contact = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsVisible(scrollTop > 300);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <React.Fragment>
            {/* SiteBreadcrumb */}
            <div className="breadcrumb-container" style={{ backgroundImage: `url(${bannerbg})`, transition: 'background-image 0.5s ease-in-out' }}>
                <SiteBreadcrumb
                    pageTitle="Contact Us"
                    pageName="Contact Us"
                />
            </div>
            {/* SiteBreadcrumb */}

            {/* ContactMain Section Start */}
            <div className="contact-main container py-5">
                <ContactMain />
            </div>
            {/* ContactMain Section End */}
           
            <div className={`position-fixed bottom-0 end-0 p-3 ${isVisible ? 'd-block' : 'd-none'} animate__animated animate__fadeIn animate__delay-1s`} style={{ zIndex: 9999 }}>
                <BackToTop />
            </div>
            {/* <Footer /> */}
        </React.Fragment>
    );
}

export default Contact;
