import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import LoginForm from "../../components/Login/LoginForm";
import AdminMenu from "../../components/AdminMenu/AdminMenu";
import UsersList from "../../components/UserList/UserList";

function Users() {
  // Destructure the auth hook to get isLogged and isAdmin
  const { isLogged, isAdmin, user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check if the authentication state is properly set
    console.log("Auth State:", { isLogged, isAdmin, user });

    // Simulate a delay to handle asynchronous state setting
    setTimeout(() => setIsLoading(false), 100); // Adjust timeout as needed
  }, [isLogged, isAdmin, user]);

  if (isLoading) {
    // Optionally, you can show a loading spinner or message here
    return <div>Loading...</div>;
  }

  // If the user is not logged in, display the login form
  if (!isLogged) {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }

  // If the user is logged in but not an admin, show unauthorized message
  if (!isAdmin) {
    return (
      <div>
        <h1>You are not authorized to access this page</h1>
      </div>
    );
  }

  // If the user is logged in and is an admin, show admin content
  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          <AdminMenu />
        </div>
        <div className="col-md-9 admin-right-side">
          <UsersList />
        </div>
      </div>
    </div>
  );
}

export default Users;
